const Time = require('./time');
const Path = require('./path');

module.exports = {
  /**
   * Format the station data for presentation as cards.
   *
   * @param data
   * @returns {{image: *, path: *, rounded: boolean, titlePrefix: string, title: *}[]}
   */
  prepareAllStationData: data => {
    const sortedStations = data.allStation.edges.sort((stationA, stationB) => {
      return stationA.node.metadatastation.weight - stationB.node.metadatastation.weight;
    });

    return sortedStations.map(row => {
      return {
        image: row.node.metadatastation.svg1by1,
        title: row.node.title.replace(/^BFBS /, ''),
        titlePrefix: 'BFBS',
        rounded: true,
        href: Path.getStationPath(row.node.title),
      };
    });
  },

  /**
   * Format the station data for the presenter stations.
   *
   * @param stations
   * @returns {*}
   */
  preparePresenterStationData: stations => {
    const sortedStations = stations.sort((stationA, stationB) => {
      return stationA.metadatastation.weight - stationB.metadatastation.weight;
    });

    return sortedStations.map(station => {
      return {
        image: station.metadatastation.svg1by1,
        title: station.title.replace(/^BFBS /, ''),
        titlePrefix: 'BFBS',
        rounded: true,
        href: Path.getStationPath(station.title),
      };
    });
  },

  /**
   * Construct the touch points array for the get in touch component.
   *
   * @param tel
   * @param email
   * @param customUris
   */
  prepareGetInTouch: (tel, email, customUris) => {
    const touchPoints = [];

    if (tel) {
      touchPoints.push({
        icon: 'call-answer',
        text: tel,
        url: `tel:${tel}`,
      });
    }

    if (email) {
      touchPoints.push({
        icon: 'envelope',
        text: email,
        url: `mailto:${email}`,
      });
    }

    const facebook = module.exports.getFacebookUrl(customUris);
    if (facebook) {
      touchPoints.push({
        icon: 'facebook',
        text: 'Facebook',
        url: facebook,
      });
    }

    const twitter = module.exports.getTwitterUrl(customUris);
    if (twitter) {
      touchPoints.push({
        icon: 'twitter',
        text: 'Twitter',
        url: twitter,
      });
    }

    const instagram = module.exports.getInstagramUrl(customUris);
    if (instagram) {
      touchPoints.push({
        icon: 'instagram',
        text: 'Instagram',
        url: instagram,
      });
    }

    return touchPoints;
  },

  /**
   * Return the URL for the given type.
   *
   * @param customUris
   * @param type
   * @returns {null|*}
   */
  getCustomUriType: (customUris, type) => {
    for (let i = 0; i < customUris.length; i++) {
      if (customUris[i].description.toLowerCase() === type) {
        return customUris[i].uri;
      }
    }
    return null;
  },

  /**
   * Get the stream URL from the array of custom URIs
   * Currently assumes that we are only interested in the AAC audio format.
   *
   * @param customUris
   * @returns {*}
   */
  getStreamAacUrl: customUris => {
    for (let i = 0; i < customUris.length; i++) {
      if (customUris[i].uri.match(/\.aac/gi)) {
        return customUris[i].uri;
      }
    }
    return null;
  },

  /**
   * Get the stream URL from the array of custom URIs
   * Currently assumes that we are only interested in the AAC audio format.
   *
   * @param customUris
   * @returns {*}
   */
  getStreamMp3Url: customUris => {
    for (let i = 0; i < customUris.length; i++) {
      if (customUris[i].uri.match(/\.mp3/gi)) {
        return customUris[i].uri;
      }
    }
    return null;
  },

  /**
   * Get the twitter URL from the array of custom URIs
   *
   * @param customUris
   * @returns {*}
   */
  getTwitterUrl: customUris => {
    return module.exports.getCustomUriType(customUris, 'twitter');
  },

  /**
   * Get the twitter URL from the array of custom URIs
   *
   * @param customUris
   * @returns {*}
   */
  getFacebookUrl: customUris => {
    return module.exports.getCustomUriType(customUris, 'facebook');
  },

  /**
   * Get the twitter URL from the array of custom URIs
   *
   * @param customUris
   * @returns {*}
   */
  getInstagramUrl: customUris => {
    return module.exports.getCustomUriType(customUris, 'instagram');
  },

  /**
   * Prepares epg data
   * @param epg
   * @returns array
   */
  prepareEpg: epg => epg.map(day => {
    if (day.broadcasts && day.broadcasts.length) {
      day.broadcasts = day.broadcasts.map(broadcast => {
        if (broadcast.presenter && broadcast.presenter.length) {
          broadcast.presenters = broadcast.presenter.map(presenter => {
            return {
              name: presenter.title,
              image: presenter.field_values.image,
              href: Path.getPresenterPath(presenter.title),
            };
          });
          broadcast.image = {image: broadcast.name, src: broadcast.presenters[0].image};
        }

        if (broadcast.start && broadcast.start.date) {
          broadcast.start.time = Time.prepareTimezone(broadcast.start).format('LT');
          broadcast.start.timestamp = Time.prepareTimezone(broadcast.start).format('X');
        }

        if (broadcast.stop && broadcast.stop.date) {
          broadcast.stop.time = Time.prepareTimezone(broadcast.stop).format('LT');
          broadcast.stop.timestamp = Time.prepareTimezone(broadcast.stop).format('X');
        }

        return broadcast;
      });
    }
    else {
      day.broadcasts = [];
    }

    // BFBSD-1220: Remove news items from schedule.
    var newsItems = ['Forces News', 'Forces 24', 'Headlines and Sport', 'Headlines & Sport'];
    day.broadcasts = day.broadcasts.filter(broadcast => !(newsItems.indexOf(broadcast.title) > -1));

    return day;
  }),

  /** Transform bearers data.
   *
   * @param bearers
   * @returns {*}
   */
  prepareBearerData: bearers => {
    const transformedBearers = {};

    bearers.map(bearer => {
      const nameFrags = bearer.name.split(' - ');
      var key = 'noFilter';
      var name = bearer.name;
      var freq;

      if (nameFrags.length === 3) {
        key = nameFrags.shift();
        name = nameFrags.join(' - ');
      }

      if (bearer.type === 'fm') {
        freq = `${bearer.data.freq / 100} MHz`;
      }
      else if (bearer.type === 'am') {
        freq = `${bearer.data.freq / 10} KHz`;
      }
      else {
        // This is the case for 'DAB' type where we just print the type.
        freq = bearer.type;
      }

      transformedBearers[key] = transformedBearers[key] || [];
      transformedBearers[key].push({
        name: name,
        freq: freq,
      });
    });
    return transformedBearers;
  },
};
