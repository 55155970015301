/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import SvgSprite from '@bfbs/storybook/dist/components/atoms/svg-sprite/svg-sprite';
import Breadcrumbs from '@bfbs/storybook/dist/components/molecules/breadcrumbs/breadcrumbs';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, breadcrumbs }) => {
  return (
    <>
      <Header />
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <main>{children}</main>
      <Footer />
      <SvgSprite />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array,
};

Layout.defaults = {
  breadcrumbs: [],
};

export default Layout;
