const machineName = require('./machineName');

const basePath = '/';

module.exports = {
  basePath: basePath,
  stationList: `${basePath}stations/`,
  presenterList: `${basePath}presenters/`,
  catchupList: `${basePath}catchup/`,
  podcastList: `${basePath}podcasts/`,

  /**
   * Get the path for an individual station.
   *
   * @param stationName
   * @returns {string}
   */
  getStationPath: stationName => {
    return `${module.exports.stationList}${machineName(stationName)}`;
  },

  /**
   * Get the path for a station schedule.
   *
   * @param stationName
   * @returns {string}
   */
  getStationSchedulePath: stationName => {
    return `${module.exports.stationList}${machineName(stationName)}/schedule`;
  },

  /**
   * Get the path for an individual presenter.
   *
   * @param presenterName
   * @returns {string}
   */
  getPresenterPath: presenterName => {
    return `${module.exports.presenterList}${machineName(presenterName)}`;
  },

  /**
   * Get the path for an individual catch up programme.
   *
   * @param programmeName
   * @returns {string}
   */
  getCatchupPath: programmeName => {
    return `${module.exports.catchupList}${machineName(programmeName)}`;
  },

  /**
   * Get the path for an individual podcast series.
   *
   * @param seriesName
   * @returns {string}
   */
  getPodcastPath: seriesName => {
    return `${module.exports.podcastList}${machineName(seriesName)}`;
  },

  /**
   * Return the station path for the radioplayer with the given station Gatsby ID.
   *
   * @param id
   * @returns {string}
   */
  getStationPlayerUrl: id => {
    return `${module.exports.basePath}radioplayer/station/${id}`;
  },

  /**
   * Return the catch up path for the radioplayer with the given station Gatsby ID.
   *
   * @param id
   * @returns {string}
   */
  getCatchupPlayerUrl: id => {
    return `${module.exports.basePath}radioplayer/episode/${id}`;
  },

  /**
   * Return the podcast path for the radioplayer with the given station Gatsby ID.
   *
   * @param id
   * @returns {string}
   */
  getPodcastPlayerUrl: id => {
    return `${module.exports.basePath}radioplayer/podcast/${id}`;
  },
};
