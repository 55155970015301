import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import StyledFooter from '@bfbs/storybook/dist/components/organisms/footer/footer';

const Footer = () => {
  const { allBfbsMenus, site } = useStaticQuery(
    graphql`
      query FooterMenu {
        allBfbsMenus(filter: { type: { eq: "footer-menu" } }) {
          nodes {
            text
            href
            external
            menuItems {
              text
              href
            }
          }
        }

        site {
          siteMetadata {
            bfbsUrl
            socialLinks {
              icon
              label
              text
              url
            }
          }
        }
      }
    `
  );

  return (
    <StyledFooter
      homepage={site.siteMetadata.bfbsUrl}
      menuItems={allBfbsMenus.nodes}
      socialLinks={site.siteMetadata.socialLinks}
    />
  );
};

export default Footer;
