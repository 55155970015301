const moment = require('moment');

module.exports = {
    /**
     * Prepare a time considering the timezone.
     *
     * @param time The time object.
     * @param time.date The full date string
     * @param time.timezone The timezone diff.
     *
     * @returns {moment.Moment}
     */
    prepareTimezone: (time) => {
        return moment.utc(time.date).utcOffset(time.timezone || 0, true).utcOffset(time.timezone);
    },

    /**
     * Parses a timezone full string to simplified.
     *
     * @param {string} timezone
     *   The timezone information. Example: '+05:30'.
     *
     * @return {string}
     *   The timezone data parsed to be displayed along GMT. Example: 'GMT+5:30'.
     */
    prepareTimezoneDescription: (timezone) => {
        const raw = timezone.replace('+', '').replace('-', '');
        const parts = raw.split(':');
        const hours = parts[0];
        const minutes = parts[1];
        const hoursParsed = Number(hours);
        const minutesParsed = Number(minutes) === 0 ? '' : `:${minutes}`;

        return `GMT${timezone.charAt(0)}${hoursParsed}${minutesParsed}`;
    },

    /**
     * Returns the offset from UTC.
     *
     * @returns {number}
     */
    getTimezoneDiff: () => 1,

    /**
     * Returns the current time using the current timezone.
     *
     * @returns {moment.Moment}
     */
    now: () => {
        const offset = module.exports.getTimezoneDiff();
        return moment.utc().utcOffset(offset);
    },
};