import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import StyledHeader from '@bfbs/storybook/dist/components/organisms/header/header';

const Header = () => {
  const { allBfbsMenus, site } = useStaticQuery(
    graphql`
      query MainMenu {
        site {
          siteMetadata {
            bfbsUrl
          }
        }
        allBfbsMenus(filter: { type: { eq: "main-menu" } }) {
          nodes {
            text
            href
            external
            menuItems {
              text
              href
            }
          }
        }
      }
    `
  );

  return <StyledHeader homepage={site.siteMetadata.bfbsUrl} menuItems={allBfbsMenus.nodes} />;
};

export default Header;
